var navLinks = '[data=onsite-navlink]';
var elementsToAnimate = $('html, body');


var navigateOnSite = function(event) {
    event.preventDefault();
    var anchorLink = event.target.attributes.href.value;

    if ($(anchorLink).length == 0) {
        console.dir('Should be a onsite navlink but there is no matching anchor id');
        return;
    } else {
        elementsToAnimate.animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    }
}

function addEventListenerOnsiteNavigation() {
    $(document).on('click', navLinks, navigateOnSite);
}

module.exports = addEventListenerOnsiteNavigation;
