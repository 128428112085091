mixin oPraxis(praxis, isAcupunctureSite)
    each val in praxis
        .container-fluid.bg-gray
            section.praxis.container#praxis.section-padding-bottom
                .row
                    .col-12
                        +a-title(val.subheadline)
                .row.js-team-members
                    - var count = val.teamMembers.length
                    if (count <= 1)
                        each item in val.teamMembers
                            .col.single-member
                                +mTeamMember(item.image, item.name, item.profil)
                    else
                        each item in val.teamMembers
                            .col-md-4.multiple-member
                                +mTeamMember(item.image, item.name, item.profil)
