doctype html
head
    block imports
    block meta
    link(href='/app.css', rel='stylesheet')

body
    block content
    script(src='https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js')
    script(src='/vendor.js')
    script(src='/app.js')
    link(href='https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css', rel='stylesheet')
    script(src= 'https://cdn.jsdelivr.net/jquery.slick/1.6.0/slick.min.js')
    link(href= 'https://cdn.jsdelivr.net/jquery.slick/1.6.0/slick.css', rel='stylesheet')

