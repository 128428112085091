mixin o-textpage(textData, lang)
  if (lang = 'DE')
    - var mainNavTitle = 'Zur Hauptseite'
    - var acuNavTitle = 'Zur Akupunktur'
    - var acuNavUrl = '/akupunktur'
  else
    - var mainNavTitle = 'please set spanish link in o-textpage.pug'
    - var acuNavTitle = 'please set spanish link in o-textpage.pug'
    - var acuNavUrl = '/please set spanish link in o-textpage.pug'
  .container-fluid
    .row.float-right.w-100.pt-5.d-none.d-sm-block
      .col.text-center
        +a-link('/', ''+mainNavTitle , 'chevron-prev pr-3')
        +a-link(''+acuNavUrl, ''+acuNavTitle , 'chevron-next text-uppercase pl-3')
    header#header
      .row.section-main-nav
          .col.logo
            +a-logo('../img/logo.svg')
  .container
    .row.pb-5.d-block.d-sm-none
      .col-md-8.ml-md-auto.mr-md-auto.text-center
        +a-link('/', 'Zur Hauptseite', 'chevron-prev pb-3 pr-3')
        +a-link(''+acuNavUrl, ''+acuNavTitle, 'chevron-next text-uppercase pl-3')
    each val in textData
      .row
        .col.text-center
          +a-subheadline(val.title)
      .row.pb-5
        .col-md-8.ml-md-auto.mr-md-auto
          +a-text-block(val.text)
    .row.pb-5.text-center
      .col.ml-md-auto.mr-md-auto.pb-5
        +a-link('/', ''+mainNavTitle, 'chevron-prev pb-3 pr-3')
        +a-link(''+acuNavUrl, ''+acuNavTitle, 'chevron-next text-uppercase pl-3')



