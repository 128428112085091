mixin o-slideshow(slideshowId, slideshow)
    each val in slideshow
        div(id=slideshowId, class='carousel slide slideshow', data-ride='false', data-interval='false')
            .carousel-inner(role='listbox')
                .start-item.carousel-item.active(style='background-image: url(' + val.firstImage + ')')
                    .carousel-caption.d-none.d-block
                        +a-subheadline(val.firstSlideTitle)
                        +a-textbig(val.firstSlideDesc)
                each item in val.items
                    +m-slideshow-item(item)
                a.carousel-control-prev(href='#' + slideshowId + '', role='button', data-slide='prev')
                    span.carousel-control-prev-icon(aria-hidden='true')
                    span.sr-only Previous
                a.carousel-control-next(href='#' + slideshowId + '', role='button', data-slide='next')
                    span.carousel-control-next-icon(aria-hidden='true')
                    span.sr-only Next
