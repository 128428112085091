var elementsToSlide = $('.js-team-members');
var slickSliderOptions = {
    mobileFirst: true,
    arrows: false,
    speed: 300,
    slidesToShow: 1,
    dots: true,
    responsive: [{
        breakpoint: 768,
        settings: 'unslick'
    }]
}

function addMobileSliderTeam() {
    elementsToSlide.slick(slickSliderOptions);
}

module.exports = addMobileSliderTeam;
