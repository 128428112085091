function addGoogleMap() {

  window.initMap = function initMap() {

      var uluru = {lat: 49.880010, lng: 8.673540};
      var map = new google.maps.Map(document.getElementById('map'), {
          zoom: 15,
          center: uluru
      });
      var marker = new google.maps.Marker({
          position: uluru,
          map: map
      });
  }

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDEVzCV4XT4am1LXYzTYPORMWDoHBBw-Aw&callback=initMap';
    $('body').append(script);

}

module.exports = addGoogleMap;
