mixin oServices(service, isAcupunctureSite)
    each val in service
        .container-fluid.bg-light
            section.container#leistungen.services.px-md-0
                .row
                    .col-12
                        +a-title(val.title)
                .row
                    if (isAcupunctureSite)
                        .col-lg-4.bg-white.text-center.pb-4
                            img.img-fluid(src=val.imageAcupuncture)
                            br
                            +a-subheadline(val.subheadline)
                        .col-lg-8.bg-primary
                            .row
                                .col-md-6.text-center
                                    img.img-fluid(src=val.imgDoctor)
                                    br
                                    +a-link(val.akuLinkUrl, val.akuLinkName, val.akuLinkSpecialClass)
                                .col-md-6.align-self-center
                                    ul
                                        each item in val.list
                                            +a-list-element(item.linkText)
                    else
                        .col-lg-8.bg-white
                            .row
                                .col-md-6.text-center.pb-4
                                    img.img-fluid(src=val.imgDoctor)
                                    br
                                    +a-subheadline(val.subheadline)
                                .col-md-6.align-self-center
                                    ul
                                        each item in val.list
                                            +a-list-element(item.linkText)
                        .col-lg-4.bg-primary.text-center.pb-4
                            img.img-fluid(src=val.imageAcupuncture)
                            br
                            +a-link(val.akuLinkUrl, val.akuLinkName, val.akuLinkSpecialClass)
                .row
                    .col-12.bg-dark.link-box.text-center
                        +a-link(val.spanishLinkUrl, val.spanishLinkName)

