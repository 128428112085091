mixin oWelcome(welcome)
    each val in welcome
        .welcome.container
            .row
                .col
                    +a-subheadline(val.subheadline)
            .row
                .col
                    +a-text-block(val.textblock)
            .row
                .col
                    +a-button(val.button)
        a.scroll-down.animated.slideInDown.infinite(href= '#sprechzeiten', data='onsite-navlink')
