block content
    .container-fluid.bg-headerImage.main-site-header
        //- Section: Header
        -
            var oHeaderFrontpage =  [{
            logoSrc: 'img/logo.svg',
            navigationLinks: [{
            url: '#sprechzeiten', name: 'Sprechzeiten', attribute: 'onsite-navlink'},{
            url: '#leistungen', name: 'Leistungen', attribute: 'onsite-navlink'},{
            url: '#praxis', name: 'Unser Praxisteam', attribute: 'onsite-navlink'},{
            url: '#termin', name: 'Termin vereinbaren', attribute: 'onsite-navlink'},{
            url: '#anfahrt', name: 'Anfahrt', attribute: 'onsite-navlink'
            }],
            button: [{url: '/akupunktur', text: 'Akupunktur &rsaquo;', specialclass: 'btn-secondary btn-main-switch'},{url: '/es', text: 'Hablamos espa&Ntilde;ol &rsaquo;', specialclass: 'btn-secondary'}]
            }]
        +oHeader(oHeaderFrontpage)


        //- Section: Welcome
        -
            var oWelcomeFrontpage = [{
            subheadline: 'Gude und herzlich Willkommen!',
            textblock: 'Unsere Praxis am Oberfeld ist Ihre medizinische Heimat am Rande des Martinsviertels. Wir nehmen uns Zeit für Ihre Gesundheit.',
            button: [{
            url: 'https://www.termin-patmed.de/p/praxis-am-oberfeld', text: 'Termin vereinbaren', specialclass: 'btn-primary icon-appointment'}, {
            url: '#anfahrt', text: 'Anrufen', specialclass: 'btn-primary icon-phone d-none d-md-inline-block', attribute: 'onsite-navlink'}, {
            url: 'tel:+4961518055295', text: 'Anrufen', specialclass: 'btn-primary icon-phone  d-sm-block d-md-none d-lg-none d-xl-none'
            }],
            }]
        +oWelcome(oWelcomeFrontpage)

    //- Section: Hours
    -
        var oHoursFrontpage = [{
        subheadline: 'Sprechzeiten',
        businessHours: 'Montag bis Freitag: 07.00 Uhr bis 14.00 Uhr, <br />sowie nach Vereinbarung',
        image: 'img/JPP_Icon_Fahrrad_Wimpel.svg',
        infotextHeadline: 'Der Herr Doktor radelt gerade.',
        infotext: 'Bei uns ist der Arztkoffer ein Rucksack, denn für Hausbesuche schwingt sich Dr. Pech auf’s Rad. Und dann heißt’s am Telefon: „Der Herr Doktor radelt gerade.”',
        slideshow: [{
        firstImage: 'img/Slideshow0.jpg',
        firstSlideTitle: 'Schön warten!',
        firstSlideDesc: 'Warten ist langweilig? Bei uns vielleicht ein bisschen weniger.',
        items: [
        'img/Slideshow0.jpg',
        'img/Slideshow1.jpg',
        'img/Slideshow2.jpg',
        'img/Slideshow3.jpg',
        'img/Slideshow4.jpg'
        ]
        }]
        }]
    +oHours(oHoursFrontpage)

    //- Section: Services
    -
        var oServiceFrontpage = [{
        title: 'Leistungen',
        subheadline: 'Hausärztliche Versorgung',
        list: [{
        linkText: 'Impfen'
        },{ linkText: 'Check-up und Vorsorge'
        },{ linkText: 'Ultraschall'
        },{ linkText: '(Langzeit)-EKG, Ergometrie'
        },{ linkText: 'Lungenfunktion'
        },{ linkText: 'Elektro- und Wärmetherapie'
        },{ linkText: 'Hautkrebsscreening'
        }],
        akuLinkUrl: '/akupunktur',
        akuLinkName: 'Akupunktur',
        akuLinkSpecialClass: 'chevron-next',
        spanishLinkUrl: '/es',
        spanishLinkName: 'Hablamos espa&Ntilde;ol',
        imgDoctor: 'img/JPP_Icon_Stethoskop.svg',
        imageAcupuncture: 'img/JPP_Icon_Akupunktur.svg'
        }]
    +oServices(oServiceFrontpage)

    //- Section: Philosophy
    -
        var oPhilosophyFrontpage = [{
        quote: [{ text: 'Unsere Philosophie:<br/>Patient &ne; Karteikarte'}],
        subheadline: 'Philosophie',
        textblock: [
        '<p>' +
        '"Klar. Ich könnte Ihnen erzählen, dass ich ein super Diagnostiker bin und unsere Praxis mit modernen Instrumenten ausgestattet ist. Dass ich eine fundierte klassisch schulmedizinische Ausbildung genossen und in meiner Zeit in verschiedenen Kliniken gelernt habe, wie man unter Hochdruck akkurat arbeitet' +
        '</p>' +
        '<p>' +
        '<strong>Was für Sie jedoch am wichtigsten ist:</strong> Ich bin Hausarzt geworden, weil ich mich um meine Patienten kümmern möchte. Denn ich arbeite mit Menschen und nicht mit Karteikarten. Ich bin Ihre medizinische Zentrale. Ich kann Ihre Lage einschätzen und eine angemessene Behandlung empfehlen, weil ich mich für Sie interessiere. Ein Beispiel: Ein einfacher Husten kann vieles bedeuten. Erkältung? Allergische Reaktion? Stress oder psychische Beschwerden? Ich löchere Sie mit Fragen, um der Ursache auf den Grund zu gehen. Manchmal müssen wir tiefer graben – und ja, manchmal ist\'s wirklich einfacher Husten.' +
        '</p>' +
        '<p>' +
        'Dann gibt\'s eben doch Hustensaft. Wohl bekomm\'s!"' +
        '</p>'
        ]
        }]
    +oPhilosophy(oPhilosophyFrontpage)

    //- Section: Praxis
    -
        var oPraxisFrontpage = [{
        subheadline: 'Unser Praxisteam',
        teamMembers: [{
        image: 'img/Mitarbeiter-JPP.jpg',
        name: 'Dr. Jan Patrick Pech',
        profil: 'Um sein Studium zu finanzieren, produzierte Dr. Jan Patrick Pech einst Buttons für lokale Punkbands. Seine medizinische Karriere startete in der Unfallchirurgie mit handfester Arbeit unter Zeitdruck. In seiner eigenen Praxis nimmt er sich jetzt gerne die Zeit, zum nächsten Hausbesuch zu radeln.'
        },{
        image: 'img/Mitarbeiter-Carmo.jpg',
        name: 'Carmo',
        profil: 'Carmo ist Medizinische Fachangestellte und lebendige Praxisgeschichte. Schon bei Dr. Monika Pech war sie das Organisationstalent mit offenem Ohr für die Patienten. Und auch jetzt ist sie die Konstante, die die Praxis am Oberfeld zusammenhält.'
        },{
        image: 'img/Mitarbeiter-Patricia.jpg',
        name: 'Patricia',
        profil: 'Carmo und Patricia sind ein eingespieltes Team. Nach ihrer Ausbildung zur Medizinischen Fachangestellten im Hause Pech probierte sich Patricia im Bereich der Dialyse aus – um nun in die Praxis am Oberfeld zurückzukehren. Das Dream Team ist wieder vereint!'
        },{
        image: 'img/Mitarbeiter-Nurdan.jpg',
        name: 'Nurdan',
        profil: 'Nurdan ist durch das Stahlbad gemeinsamer Notdienste im ÄBD gegangen, um ihre Qualitäten als Medizinische Fachangestellte nach langen Jahren in der Studienambulanz der Uniklinik Mainz nun am Oberfeld einzubringen. Niemand motiviert Patienten schöner für ein gutes Ergebnis bei der Lungenfunktion und hat obendrein immer ein Lächeln für sie übrig.'
        },{
        image: 'img/Mitarbeiter-Call.jpg',
        name: 'Aaron',
        profil: 'Das gesamte Praxisteam kann sich zu jeder Tages- und Nachtzeit auf die künstliche Intelligenz von Aaron verlassen. Unser digitaler Telefonassistent ist der neueste Mitarbeiter der Praxis am Oberfeld und wird Ihren Anruf entgegennehmen, wenn Sie außerhalb der Sprechzeiten anrufen, oder wenn das Praxis-Team gerade nicht verfügbar ist. Teilen Sie ihm Rezeptanfragen, Terminwünsche und individuelle Nachrichten mit - kein "besetzt", kein unnötiges Warten, direkte Bearbeitung garantiert!'
        }]
        }]
    +oPraxis(oPraxisFrontpage)

    //- Section: History
    -
        var oHistoryFrontpage= [{
        subheadline: 'In dritter Generation um Ihr Wohl besorgt',
        timeline: [{
        image: 'img/JPP_Icon_Oma_V2.svg',
        year: '1959',
        details: 'In den steifen Fünfzigern studierte Maria Herz Medizin. Ihr Ziel: Die eigene Hausarztpraxis. Die existiert nun schon in der dritten Generation. Ziel erreicht!'
        },{
        image: 'img/JPP_Icon_Mama_V2.svg',
        year: '1986',
        details: 'Über 35 Jahre praktizierte Dr. Monika Pech in der Praxis am Oberfeld und begleitete in dieser Zeit zusammen mit ihrer Schwester Dr. Gabriele Gemmer unzählige Patientinnen und Patienten.'
        },{
        image: 'img/JPP_Icon_JPP_V2.svg',
        year: '2017',
        details: 'Der Nachwuchs kommt: Nach Großmutter und Frau Mama übernimmt Dr. Jan Patrick Pech die Praxis am Oberfeld. Mit neuen Ideen und der einen oder anderen eingerissenen Wand.'
        }]
        }]
    +oHistoryBenefit(oHistoryFrontpage)


    //- Section: Appointment
    -var oAppointmentTitle= 'Online-Termin&shyvereinbarung'
    +oAppointment(oAppointmentTitle)

    //- Section: Contact
    -
        var oContactFrontpage = [{
        title: 'Anfahrt',
        adress: 'Dieburger Str. 113 <br> 64287 Darmstadt',
        details: 'Tel. 06151 8055295 <br> Fax 06151 8055294 <br> <a href="mailto:kontakt@praxis-am-oberfeld.de">kontakt@praxis-am-oberfeld.de</a>'

        }]
    +oContact(oContactFrontpage)

    //- Section: Footer
    -
        var oFooterLinks = [{
        text: 'Impressum',
        url: '/impressum'
        },
        {
        text: 'Datenschutzerklärung',
        url: '/datenschutz'
        }]
    +oFooter(oFooterLinks)
    +m-to-top-button()
