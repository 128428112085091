mixin oContact(contact)
    each val in contact
        .container-fluid.bg-gray
            section.contact#anfahrt
                .row
                    .col-lg-6
                        a(href="https://goo.gl/maps/SwEgiss15QDRiTJz6", class="d-block" target="_blank") 
                            img(src="../img/map.png", alt="Anfahrtkarte", class="mw-100")
                    .col-lg-6.details
                        +a-title(val.title)
                        .ver-center
                            +a-textbig(val.adress)
                            +a-textbig(val.details)

