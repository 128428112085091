block content
    .container-fluid.bg-headerImage.main-site-header
        //- Section: Header
        -
            var oHeaderFrontpageEs =  [{
            logoSrc: '../img/logo.svg',
            navigationLinks: [{
            url: '#sprechzeiten', name: 'Horarios', attribute: 'onsite-navlink'},{
            url: '#leistungen', name: 'Servicios', attribute: 'onsite-navlink'},{
            url: '#praxis', name: 'Nuestra consulta', attribute: 'onsite-navlink'},{
            url: '#termin', name: 'Cita previa', attribute: 'onsite-navlink'},{
            url: '#anfahrt', name: 'Como llegar', attribute: 'onsite-navlink'
            }],
            button: [{url: '/es/acupuntura', text: 'Acupuntura &rsaquo;', specialclass: 'btn-secondary btn-main-switch'},{url: '/', text: 'Wir sprechen deutsch', specialclass: 'btn-secondary'}]
            }]
        +oHeader(oHeaderFrontpageEs)


        //- Section: Welcome
        -
            var oWelcomeFrontpageEs = [{
            subheadline: 'Gude y Bienvenidos!',
            textblock: 'Nuestra consulta am Oberfeld es su centro de salud al lado del Martinsviertel. Nos interesamos por Usted y por su estado de salud.',
            button: [{
            url: 'https://www.termin-patmed.de/p/praxis-am-oberfeld', text: 'Pedir cita', specialclass: 'btn-primary icon-appointment'}, {
            url: '#anfahrt', text: 'Llamar', specialclass: 'btn-primary icon-phone d-none d-md-inline-block', attribute: 'onsite-navlink'}, {
            url: 'tel:+4961518055295', text: 'Llamar', specialclass: 'btn-primary icon-phone  d-sm-block d-md-none d-lg-none d-xl-none'

            }],
            }]
        +oWelcome(oWelcomeFrontpageEs)

    //- Section: Hours
    -
        var oHoursFrontpageEs = [{
        subheadline: 'Horarios',
        businessHours: 'Lunes a Viernes: de 07.00 a 14.00 horas,<br /> y con cita previa',
        image: '../img/JPP_Icon_Fahrrad_Wimpel.svg',
        infotextHeadline: 'El doctor esta pedaleando',
        infotext: 'Nosotros substituimos el maletín de medico por una mochila para la bicicleta que acompaña a Dr. Pech en sus visitas a domicilio.',
        slideshow: [{
        firstImage: '../img/Slideshow0.jpg',
        firstSlideTitle: 'Esperar es aburrido?',
        firstSlideDesc: 'Hacemos el tiempo de espera mas ameno... quizás!',
        items: [
        '../img/Slideshow0.jpg',
        '../img/Slideshow1.jpg',
        '../img/Slideshow2.jpg',
        '../img/Slideshow3.jpg',
        '../img/Slideshow4.jpg'
        ]
        }]
        }]
    +oHours(oHoursFrontpageEs)

    //- Section: Services
    -
        var oServiceFrontpageEs = [{
        title: 'Servicios',
        subheadline: 'Atención primaria',
        list: [{
        linkText: 'Vacunas'
        },{ linkText: 'Chequeo y prevención'
        },{ linkText: 'Sonografías'
        },{ linkText: 'Electrocardiografía en reposo y de esfuerzo'
        },{ linkText: 'Holter-EKG'
        },{ linkText: 'Función pulmonar'
        },{ linkText: 'Electroterapia y terapia de calor'
        },{ linkText: 'Acupuntura como terapia especial contra el dolor'
        }],
        akuLinkUrl: '/es/acupuntura',
        akuLinkName: 'Acupuntura',
        akuLinkSpecialClass: 'chevron-next',
        spanishLinkUrl: '/',
        spanishLinkName: 'Wir sprechen deutsch',
        imgDoctor: '../img/JPP_Icon_Stethoskop.svg',
        imageAcupuncture: '../img/JPP_Icon_Akupunktur.svg'
        }]
    +oServices(oServiceFrontpageEs)

    //- Section: Philosophy
    -
        var oPhilosophyFrontpageEs = [{
        quote: [{ text: 'Nuestra filosofía:<br/>Paciente= &ne; Tarjeta sanitaria'}],
        subheadline: 'Filosofía',
        textblock: [
        '<p>' +
        '„Claro. Puedo contarles que soy un médico cualificado y que nuestra consulta esta dotada del instrumental mas moderno. Que poseo una sólida formación basada en la medicina tradicional y en mi amplia experiencia en diferentes hospitales.' +
        '</p>' +
        '<p>' +
        '<strong>Pero lo mas importante para Usted es:</strong> soy médico porque me importan mis pacientes. Por eso, trabajo con personas y no con tarjetas sanitarias. Soy su punto de referencia en salud. Puedo valorar su estado de salud y ofrecerle el tratamiento mas adecuado, porque me intereso por Usted. Un ejemplo: una simple tos puede ser una señal de alarma ante un resfriado? una reacción alérgica? una expresión de estrés excesivo o puede reducirse a un síntoma psicológico. Le atosigaré con preguntas hasta llegar al origen del problema. A veces, tenemos que profundizar mas- y sí, a veces, es sencillamente eso, tos.' +
        '</p>' +
        '<p>' +
        'Entonces le damos jarabe contra la tos. A vuestra salud!"' +
        '</p>'
        ]
        }]
    +oPhilosophy(oPhilosophyFrontpageEs)

    //- Section: Praxis
    -
        var oPraxisFrontpageEs = [{
        subheadline: 'Nuestro equipo',
        teamMembers: [{
        image: '../img/Mitarbeiter-JPP.jpg',
        name: 'Dr. Jan Patrick Pech',
        profil: 'Para financiar sus estudios vendía Dr. Jan Patrick Pech chapas de bandas de Rock. que el mismo fabricaba. Su carrera profesional la empezó en la Traumatologia donde trabajaba casi sin descanso y sometido a una gran presión. En su consulta, por el contrario, se toma tiempo para visitar a los pacientes en sus domicilios.'
        },{
        image: '../img/Mitarbeiter-Carmo.jpg',
        name: 'Carmo',
        profil: 'Carmo es asistente médico y una pieza viva y fundamental en nuestra consulta. Ya con Dr. Monika Pech mostraba su talento organizador siempre escuchando atentamente a los pacientes. Incluso, ahora, es el pilar que mantiene al equipo unido.'
        },{
        image: '../img/Mitarbeiter-Patricia.jpg',
        name: 'Patricia',
        profil: 'Carmo y Patricia compaginan bien. Después der su formación como asistente médico en la „Casa Pech“ trabajó en la diálisis hospitalaria. Para ahora, finalmente , volver a la consulta am Oberfeld. El equipo esta reunido de nuevo!'
        },{
        image: '../img/Mitarbeiter-Nurdan.jpg',
        name: 'Nurdan',
        profil: 'Nurdan ha demostrado sus cualidades como enfermera tanto a través de su paso por las urgencias hospitalarias en el Klinikum Darmstadt como en la universidad de Mainz. Ahora aporta su experiencia a la Praxis am Oberfeld. Nadie es capaz de motivar más a los pacientes en el diagnóstico de la función pulmonar y además siempre con una sonrisa en la cara.'
        },{
        image: '../img/Mitarbeiter-Call.jpg',
        name: 'Aaron',
        profil: 'El equipo de la consulta am Oberfeld apuesta por la inteligencia artificial de Aaron. El está de día y noche siempre dispuesto para atender sus llamadas fuera del horario habitual de consulta o cuando el personal está ocupado. Sin tiempo de espera innecesario pueden pedir sus recetas, solicitar una cita para consulta o cualquier otra petición o duda.'
        }]
        }]
    +oPraxis(oPraxisFrontpageEs)

    //- Section: History
    -
        var oHistoryFrontpageEs= [{
        subheadline: 'La tercera generacion preocupada por su estado de bienestar',
        timeline: [{
        image: '../img/JPP_Icon_Oma_V2.svg',
        year: '1959',
        details: 'En los duros años cincuenta estudió Maria Herz medicina. Su objetivo: montar su propia consulta. Ahora existe ya en la tercera generación. Objetivo cumplido!'
        },{
        image: '../img/JPP_Icon_Mama_V2.svg',
        year: '1986',
        details: 'Mas de 35 años pasó Dr. Monika Pech en la consulta am Oberfeld de la mano de su hermana Dr. Gabriele Gemmer prestando su ayuda a innumerables pacientes.'
        },{
        image: '../img/JPP_Icon_JPP_V2.svg',
        year: '2017',
        details: 'Ahora llega la descendencia: después de la abuela y la mamá coge Dr Jan Patrick Pech el mando de la consulta am Oberfeld con nuevas ideas y con alguna que otra pared hecha añicos.'
        }]
        }]
    +oHistoryBenefit(oHistoryFrontpageEs)


    //- Section: Appointment
    -var oAppointmentTitleFrontpageEs= 'Pedir cita online'
    +oAppointment(oAppointmentTitleFrontpageEs)

    //- Section: Contact
    -
        var oContactFrontpageEs = [{
        title: 'Como llegar',
        adress: 'Dieburger Str. 113 <br> 64287 Darmstadt',
        details: 'Tel. 06151 8055295 <br> Fax 06151 8055294 <br> <a href="mailto:kontakt@praxis-am-oberfeld.de">kontakt@praxis-am-oberfeld.de</a>'

        }]
    +oContact(oContactFrontpageEs)

    //- Section: Footer
    -
        var oFooterLinksFrontpageEs = [{
        text: 'Impressum',
        url: '/impressum'
        },
        {
        text: 'Datenschutzerklärung',
        url: '/datenschutz'
        }]
    +oFooter(oFooterLinksFrontpageEs)
    +m-to-top-button()
