//globals: $, jQuery, Tether, Popper see config

// import "bootstrap";

// adds all custom Bootstrap jQuery plugins
// see all plugins here: http://getbootstrap.com/javascript/

var addOnsiteNavigation = require("./js/onsite-navigation-module.js");
//var addGoogleMap = require("./js/map-module.js");

var addEventListenerNavigationToggle = require("./web/molecule/m-navigation/m-navigation.js");
var addMobileSliderTeam = require("./web/organism/o-praxis/o-praxis.js");
var toTopButton = require("./web/molecule/m-to-top-button/m-to-top-button.js");

document.addEventListener("DOMContentLoaded", function () {
  addEventListenerNavigationToggle();
  addOnsiteNavigation();
  addMobileSliderTeam();
  toTopButton();
  //addGoogleMap();
});
