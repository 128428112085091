var navIcon = $('.nav-toggle');
var navToToggle = $('.navigation')

var toggleIconAndNavbar = function() {
    event.preventDefault();
    navIcon.toggleClass('active');
    navToToggle.toggleClass('active');
}

function addEventListenerNavigationToggle() {
    navIcon.on('click', toggleIconAndNavbar);
}

module.exports = addEventListenerNavigationToggle;
