mixin oPhilosophy(philosophyData)
    each val in philosophyData
        section.container-fluid.section-padding-bottom
            .philosophy.container
                .row
                    .col
                        +a-title(val.subheadline)
                .row.align-items-center
                    .col-md-6.text-center
                        +a-quote(val.quote)
                    .col-md-6
                        +a-text-block(val.textblock)
