mixin oHours(hours, isAcupunctureSite)
    each val in hours
        .container-fluid
            section.hours.container#sprechzeiten
                .row
                    .col
                        +a-title(val.subheadline)
                .row
                    .col-md-6.px-0.section-padding-bottom
                        .time-designation.bg-gray.text-center
                            +a-text-block(val.businessHours)
                        .info-text.text-center
                            +a-subheadline(val.infotextHeadline)
                            .row.align-items-center.pb-4
                                if isAcupunctureSite
                                    .col.text-center
                                        +a-quote(val.quote)
                                else
                                    .col.p-0
                                        img.float-md-left.float-lg-left.float-xl-left(src= val.image)
                                    .col
                                        +a-text-block(val.infotext)
                    .col-md-6.p-0.text-center
                        if isAcupunctureSite
                            img.max-width100.float-md-left.float-lg-left.float-xl-left(src= val.image2)
                        else
                            +o-slideshow('hours-section-slideshow', val.slideshow)
