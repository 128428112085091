block content
    .container-fluid.bg-headerImage.acupuncture-header
        //- Section: Header
        -
            var oHeaderServiceAcuEs = [{
            logoSrc: '../../img/logo.svg',
            navigationLinks: [{
            url: '#sprechzeiten', name: 'Horarios', attribute: 'onsite-navlink'},{
            url: '#leistungen', name: 'Servicios', attribute: 'onsite-navlink'},{
            url: '#praxis', name: 'Nuestra consulta', attribute: 'onsite-navlink'},{
            url: '#termin', name: 'Cita previa', attribute: 'onsite-navlink'},{
            url: '#anfahrt', name: 'Como llegar', attribute: 'onsite-navlink'
            }],
            button: [{url: '/es', text: 'Página inicial &rsaquo;', specialclass: 'btn-secondary btn-main-switch'},{url: '/akupunktur', text: 'Wir sprechen deutsch &rsaquo;', specialclass: 'btn-secondary'}]
            }]
        +oHeader(oHeaderServiceAcuEs)

        //- Section: Welcome
        -
            var oWelcomeAServiceAcuEs = [{
            subheadline: 'Acupuntura en la consulta am Oberfeld',
            textblock: 'El tratamiento mas suave con agujas',
            button: [{
            url: '#termin', text: 'Pedir cita', specialclass: 'btn-primary icon-appointment', attribute: 'onsite-navlink'}, {
            url: '#anfahrt', text: 'Llamar', specialclass: 'btn-primary icon-phone d-none d-md-inline-block', attribute: 'onsite-navlink'}, {
            url: 'tel:+4961518055295', text: 'Llamar', specialclass: 'btn-primary icon-phone  d-sm-block d-md-none d-lg-none d-xl-none'
            }]
            }]
        +oWelcome(oWelcomeAServiceAcuEs)

    //- Section: Hours
    -
        var oHoursServiceAcuEs = [{
        subheadline: 'HORARIOS',
        businessHours: 'Cita previa',
        image: '',
        infotextHeadline: '',
        quote: [{
        text: '"QUIEN ACTÚA CON DELICADEZA, LLEGA LEJOS"',
        footer: 'proverbio chino'
        }],
        image2: '../../img/Slideshow-akupunktur-1.jpg'
        }]
    +oHours(oHoursServiceAcuEs, true)

    //- Section: Benefits
    -
        var oBenefitServiceAcuEs = [{
        subheadline: 'CUANDO APLICAMOS LA ACUPUNTURA?',
        timeline: [{
        image: '../../img/JPP_Icon_Schmerzen.svg',
        year: 'Dolores',
        details: 'Principalmente para tratar dolores de espalda y de rodilla. Aunque la acupuntura también ha demostrado científicamente su eficacia en el tratamiento de dolores crónicos de cabeza, o problemas en las articulaciones de hombro y codo.'
        },{
        image: '../../img/JPP_Icon_Schlafstoerungen.svg',
        year: 'Insomnio, problemas para conciliar el sueño',
        details: '20% de la población mundial tiene problemas de sueño. La acupuntura ofrece una alternativa para luchar contra los síntomas derivados de la falta de sueño y mejorar la calidad de vida de nuestros pacientes.'
        },{
        image: '../../img/JPP_Icon_Allergien.svg',
        year: 'Alergias',
        details: 'Con ayuda de la acupuntura tratamos la alergia al polen de manera inocua para el paciente.'
        }]
        }]
    +oHistoryBenefit(oBenefitServiceAcuEs, true)

    //- Section What is Acupuncture

    -
        var oPhilosophyServiceAcuEs = [{
        quote:  [{ text: 'Eine jahrtausendalte chinesische Heilkunst'}],
        subheadline: 'Was ist Akupunktur',
        textblock: [
        '<p>' +
        'Die Akupunktur wurde vor etwa 3000 Jahren in China entwickelt und wird dort bis heute ergänzend in Universitäten gelehrt. Auch WHO räumt der Akupunktur einen wichtigen Platz unter den traditionellen Heilverfahren ein. Nach ihrer Lehre fließen Energiebahnen durch den Körper, die an etwa 400 Punkten beeinflusst werden können: durch Stiche (Akupunktur), Druck (Akupressur) oder Erwärmen (Moxibustion).' +
        '</p>' +
        '<p>' +
        'In einigen Behandlungsfällen übernehmen Krankenkassen die Kosten für Akupunktur – in anderen Fällen müssen Patientinnen und Patienten die Kosten selbst tragen.' +
        '</p>'
        ]
        }]
    //- +oPhilosophy(oPhilosophyServiceAcuEs)

    //- Section: Services
    -
        var oServiceServiceAcuEs = [{
        title: 'SERVICIOS',
        subheadline: 'Acupuntura',
        list: [{
        linkText: 'Vacunas'
        },{ linkText: 'Chequeo y prevención'
        },{ linkText: 'Sonografías'
        },{ linkText: 'Electrocardiografía en reposo y de esfuerzo'
        },{ linkText: 'Holter-EKG'
        },{ linkText: 'Función pulmonar'
        },{ linkText: 'Electroterapia y terapia de calor'
        },{ linkText: 'Acupuntura como terapia especial contra el dolor'
        }],
        akuLinkUrl: '/es',
        akuLinkName: 'Atención primaria',
        akuLinkSpecialClass: 'chevron-next',
        spanishLinkUrl: '/akupunktur',
        spanishLinkName: 'Wir sprechen deutsch',
        imgDoctor: '../../img/JPP_Icon_Stethoskop.svg',
        imageAcupuncture: '../../img/JPP_Icon_Akupunktur.svg'
        }]
    +oServices(oServiceServiceAcuEs, true)


    //- Section: Praxis
    -
        var oPraxisServiceAcuEs = [{
        subheadline: 'NUESTRA CONSULTA',
        teamMembers: [{
        image: '../../img/Mitarbeiter-JPP.jpg',
        name: 'Dr. Jan Patrick Pech',
        profil: 'Acupuntura funciona. De esto esta Dr. Jan Patrick Pech plenamente convencido. Como medico de familia, aplica la medicina tradicional china para aliviar muchos de los síntomas que perjudican al estado de bienestar de sus pacientes.'
        }]
        }]
    +oPraxis(oPraxisServiceAcuEs)


    //- Section: Appointment
    -var oAppointmentTitleServiceAcuEs= 'Pedir cita online'
    +oAppointment(oAppointmentTitleServiceAcuEs)

    //- Section: Contact
    -
        var oContactServiceAcuEs = [{
        title: 'Como llegar',
        adress: 'Dieburger Str. 113 <br> 64287 Darmstadt',
        details: 'Tel. 06151 8055295 <br> Fax 06151 8055294 <br> <a href="mailto:kontakt@praxis-am-oberfeld.de">kontakt@praxis-am-oberfeld.de</a>'

        }]
    +oContact(oContactServiceAcuEs)

    //- Section: Footer
    -
        var oFooterLinksServiceAcuEs = [{
        text: 'Impressum',
        url: '/impressum'
        },
        {
        text: 'Datenschutzerklärung',
        url: '/datenschutz'
        }]
    +oFooter(oFooterLinksServiceAcuEs)
    +m-to-top-button()
