block content
    .container-fluid.bg-headerImage.acupuncture-header
        //- Section: Header
        -
            var oHeaderServiceAcu = [{
            logoSrc: '../img/logo.svg',
            navigationLinks: [{
            url: '#sprechzeiten', name: 'Sprechzeiten', attribute: 'onsite-navlink'},{
            url: '#leistungen', name: 'Leistungen', attribute: 'onsite-navlink'},{
            url: '#praxis', name: 'Unser Praxisteam', attribute: 'onsite-navlink'},{
            url: '#termin', name: 'Termin vereinbaren', attribute: 'onsite-navlink'},{
            url: '#anfahrt', name: 'Anfahrt', attribute: 'onsite-navlink'
            }],
            button: [{url: '/', text: 'Hauptseite &rsaquo;', specialclass: 'btn-secondary btn-main-switch'},{url: '/es/acupuntura', text: 'Hablamos espa&Ntilde;ol', specialclass: 'btn-secondary'}]
            }]
        +oHeader(oHeaderServiceAcu)

        //- Section: Welcome
        -
            var oWelcomeServiceAcu = [{
            subheadline: 'Akupunktur in der Praxis am Oberfeld',
            textblock: 'Die sanfte Behandlung mit spitzen Nadeln.',
            button: [{
            url: '#termin', text: 'Termin vereinbaren', specialclass: 'btn-primary icon-appointment', attribute: 'onsite-navlink'}, {
            url: '#anfahrt', text: 'Anrufen', specialclass: 'btn-primary icon-phone d-none d-md-inline-block', attribute: 'onsite-navlink'}, {
            url: 'tel:+4961518055295', text: 'Anrufen', specialclass: 'btn-primary icon-phone  d-sm-block d-md-none d-lg-none d-xl-none'
            }]
            }]
        +oWelcome(oWelcomeServiceAcu)

    //- Section: Hours
    -
        var oHoursServiceAcu = [{
        subheadline: 'Sprechzeiten',
        businessHours: 'Termine nach Vereinbarung',
        image: '',
        infotextHeadline: '',
        quote: [{
        text: '"Wer sanft auftritt, kommt weit."',
        footer: 'Altes chinesisches Sprichwort'
        }],
        image2: '../img/Slideshow-akupunktur-1.jpg'
        }]
    +oHours(oHoursServiceAcu, true)

    //- Section: Benefits
    -
        var oBenefitServiceAcu = [{
        subheadline: 'Wann setzen wir Akupunktur ein?',
        timeline: [{
        image: '../img/JPP_Icon_Schmerzen.svg',
        year: 'Schmerzen',
        details: 'Wir setzen Akupunktur bei Rücken- und Knieschmerzen ein. Aber auch bei chronischen Kopfschmerzen oder Problemen an beispielsweise Schulter und Ellenbogen bestätigen – neben unseren Patienten – auch verschiedene Studien die Wirkung in der Schmerztherapie.'
        },{
        image: '../img/JPP_Icon_Schlafstoerungen.svg',
        year: 'Schlafstörungen',
        details: 'Etwa zwanzig Prozent der Weltbevölkerung leiden an Schlafstörungen. Akupunktur kann eine Alternative zur Bekämpfung der Symptome sein und hilft, die Schlafqualität unserer Patienten zu verbessern.'
        },{
        image: '../img/JPP_Icon_Allergien.svg',
        year: 'Allergien',
        details: 'Mit Hilfe der Akupunktur behandeln wir Heuschnupfen auf sanfte Art. Was unsere Patienten selbst erfahren, wird mittlerweile auch in verschiedenen Studien belegt.'
        }]
        }]
    +oHistoryBenefit(oBenefitServiceAcu, true)

    //- Section What is Acupuncture
    -
        var oPhilosophyServiceAcu = [{
        quote:  [{ text: 'Eine jahrtausendalte chinesische Heilkunst'}],
        subheadline: 'Was ist Akupunktur',
        textblock: [
        '<p>' +
        'Die Akupunktur wurde vor etwa 3000 Jahren in China entwickelt und wird dort bis heute ergänzend in Universitäten gelehrt. Auch die WHO räumt der Akupunktur einen wichtigen Platz unter den traditionellen Heilverfahren ein. Nach ihrer Lehre fließen Energiebahnen durch den Körper, die an etwa 400 Punkten beeinflusst werden können: durch Stiche (Akupunktur), Druck (Akupressur) oder Erwärmen (Moxibustion).' +
        '</p>' +
        '<p>' +
        'In einigen Fällen übernimmt die Krankenkasse die Kosten für Akupunktur – in anderen Fällen müssen Patientinnen und Patienten die Kosten selbst tragen.' +
        '</p>'
        ]
        }]

    +oPhilosophy(oPhilosophyServiceAcu)

    //- Section: Services
    -
        var oServiceServiceAcu = [{
        title: 'Leistungen',
        subheadline: 'Akupunktur',
        list: [{
        linkText: 'Impfen'
        },{ linkText: 'Check-up und Vorsorge'
        },{ linkText: 'Ultraschall'
        },{ linkText: '(Langzeit)-EKG, Ergometrie'
        },{ linkText: 'Lungenfunktion'
        },{ linkText: 'Elektro- und Wärmetherapie'
        },{ linkText: 'Hautkrebsscreening'
        }],
        akuLinkUrl: '/',
        akuLinkName: 'Hausärztliche Versorgung',
        akuLinkSpecialClass: 'chevron-next',
        spanishLinkUrl: '/es/acupuntura',
        spanishLinkName: 'Hablamos espa&Ntilde;ol',
        imgDoctor: '../img/JPP_Icon_Stethoskop.svg',
        imageAcupuncture: '../img/JPP_Icon_Akupunktur.svg'
        }]
    +oServices(oServiceServiceAcu, true)


    //- Section: Praxis
    -
        var oPraxisServiceAcu = [{
        subheadline: 'Unsere Praxis',
        teamMembers: [{
        image: '../img/Mitarbeiter-JPP.jpg',
        name: 'Dr. Jan Patrick Pech',
        profil: 'Akupunktur wirkt. Davon ist Dr. Jan Patrick Pech überzeugt. Als Hausarzt setzt er die traditionelle chinesische Medizin gezielt ein, um Krankheiten und Symptome seiner Patienten zu behandeln.'
        }]
        }]
    +oPraxis(oPraxisServiceAcu)


    //- Section: Appointment
    -var oAppointmentTitleServiceAcu= 'Online-Termin&shyvereinbarung'
    +oAppointment(oAppointmentTitleServiceAcu)

    //- Section: Contact
    -
        var oContactServiceAcu = [{
        title: 'Anfahrt',
        adress: 'Dieburger Str. 113 <br> 64287 Darmstadt',
        details: 'Tel. 06151 8055295 <br> Fax 06151 8055294 <br> <a href="mailto:kontakt@praxis-am-oberfeld.de">kontakt@praxis-am-oberfeld.de</a>'

        }]
    +oContact(oContactServiceAcu)

    //- Section: Footer
    -
        var oFooterLinksServiceAcu = [{
        text: 'Impressum',
        url: '/impressum'
        },
        {
        text: 'Datenschutzerklärung',
        url: '/datenschutz'
        }]
    +oFooter(oFooterLinksServiceAcu)
    +m-to-top-button()
