mixin mNavigation(LogoSrc, NavigationLinks, navigationButton)
    .section-main-nav
        .logo
            +a-logo(LogoSrc)
        nav.navigation
            ul
                each val in NavigationLinks
                    li
                        +a-link(val.url, val.name, val.class, val.attribute)
                +a-button(navigationButton)
        +a-button(navigationButton)
        a.nav-toggle(href= '#')
            span
