var currentWindow = $(window);
var toTopButton = $('.to-top-button');
var firstViewportElement = $('.bg-headerImage');
var bottomOfFirstViewport = firstViewportElement.offset().top + firstViewportElement.outerHeight();

function initBackToTopButton() {
    currentWindow.scroll(function() {
        var topPositionScreen = currentWindow.scrollTop();
        if (topPositionScreen && topPositionScreen >= bottomOfFirstViewport) {
            toTopButton.removeClass('invisible');
        } else {
            toTopButton.addClass('invisible');
        }
    });
};

module.exports = initBackToTopButton;
