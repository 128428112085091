mixin oHistoryBenefit(history, isAcupunctureSite)
    each val in history
        .container-fluid.bg-dark
            section.container.px-md-0(class=isAcupunctureSite ? "benefits" : "history", id=isAcupunctureSite ? "#benefits" : "#historie")
                .row
                    .col-12
                        +a-title(val.subheadline)
                    .row.block-item-wrapper.no-gutters
                        each item in val.timeline
                            .col-md-4
                                +mBlockItem(item.image, item.year, item.details)
