block content
    //- Section: Imprint
    -
        var oTextpageData = [{
            title: 'Impressum',
            text: '<p>Verantwortlich für den Inhalt dieser Website ist</p>' +
            '<p>Dr. Jan Patrick Pech Facharzt für Allgemeinmedizin <br/>' +
            'Dieburger Straße 113<br/>' +
            '64287 Darmstadt</p>' +
            '<p>Tel. 06151 8055295<br>Fax 06151 8055294<br>' +
            'E-Mail: kontakt@praxis-am-oberfeld.de<br/>' +
            'Website: www.praxis-am-oberfeld.de</p>' +
            '<p>Mitglied der Landesärztekammer Hessen seit 2009<br/>' +
            'Vertragsarzttätigkeit im Bereich der Kassenärztlichen Vereinigung Hessen</p>'
        },{
            title: 'Disclaimer',
            text: 'Wir erklären hiermit ausdrücklich, dass von uns verlinkte Seiten zum Zeitpunkt der Linksetzung frei von illegalen, ungesetzlichen und ethisch nicht vertretbaren Inhalten waren. Weder haben wir Einfluss auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der gelinkten Seiten, noch sind wir verpflichtet, diese auf Aktualität, Richtigkeit und zivil‑ oder strafrechtliche Verantwortlichkeit zu prüfen. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller gelinkten Seiten. Alle Angaben ohne Gewähr.'
        },{
            title: 'Bildrechte',
            text: 'Image by <a href="https://www.freepik.com/free-vector/customer-support-flat-design-illustration_12983846.htm#page=2&query=call%20center&position=6&from_view=keyword&track=ais">Freepik</a>'
        }]
    +o-textpage(oTextpageData, 'DE')


    //- Section: Footer
    -
        var oFooterLinks = [{
            text: 'Impressum',
            url: '/impressum'
        },
        {
            text: 'Datenschutzerklärung',
            url: '/datenschutz'
        }]
    +oFooter(oFooterLinks)

    +m-to-top-button()
